import * as SentrySvelte from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';
import browserConfig from '$lib/env/browser-config';
import { BrowserTracing } from '@sentry/tracing'; // Must import second

if (browserConfig.PUBLIC_SENTRY_DSN) {
    SentrySvelte.init({
        dsn: browserConfig.PUBLIC_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                tracePropagationTargets: ['localhost', /^\//],
                shouldCreateSpanForRequest: () => true,
            }),
        ],
        environment: browserConfig.PUBLIC_ENV_NAME,
        tracesSampleRate: browserConfig.PUBLIC_ENV_NAME === 'production' ? 0.05 : 1.0,
    });

    SentrySvelte.setTag('svelteKit', 'browser');
}

export const handleError: HandleClientError = ({ error, event }) => {
    let errorId = null;

    if (browserConfig.PUBLIC_SENTRY_DSN) {
        errorId = crypto.randomUUID();
        SentrySvelte.captureException(error, {
            contexts: {
                sveltekit: { event, errorId },
            },
        });
    }

    return {
        message: error.message,
        errorId: errorId ? errorId : 'Sentry not initiated',
    };
};
