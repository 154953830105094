import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/about/customers": [5],
		"/connect": [6],
		"/connect/api-and-sdk": [7],
		"/connect/travel-management-tool": [8],
		"/connect/white-label": [9],
		"/contact": [10],
		"/login": [11],
		"/offices": [12],
		"/offices/[slug]": [13],
		"/privacy-policy": [14],
		"/products/junction": [15],
		"/products/junction/junction-base": [16],
		"/products/junction/junction-crew": [17],
		"/products/junction/junction-flow": [18],
		"/products/junction/junction-go": [19],
		"/products/junction/junction-one": [20],
		"/products/junction/junction-pay": [21],
		"/products/junction/junction-plus": [22],
		"/resources": [23],
		"/resources/events": [24],
		"/resources/events/[slug]": [25],
		"/resources/press-room/blogs": [26,[2]],
		"/resources/press-room/blogs/[slug]": [27,[2]],
		"/resources/press-room/in-the-news": [28,[2]],
		"/resources/press-room/overview": [29,[2]],
		"/resources/press-room/press-releases": [30,[2]],
		"/resources/press-room/press-releases/[slug]": [31,[2]],
		"/resources/press-room/search": [32,[2]],
		"/resources/videos": [33],
		"/resources/videos/[id]": [34],
		"/sales-terms-of-use": [35],
		"/terms-of-use": [36],
		"/who-for": [37],
		"/who-for/travel-agencies": [38],
		"/who-for/travel-suppliers": [39]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';